import { icebergPost } from "../../../services/iceberg-api";
import { Activity } from "../types/activity";

export async function createActivity(memberId: string, activity: Activity) {
  try {
    await icebergPost("/activity_feed", {
      queryParams: {
        member_id: memberId,
      },
      body: activity,
    });
  } catch (e) {
    throw new Error(`Could not create activity: ${e}`);
  }
}
