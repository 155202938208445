import React from "react";
import { Link } from '@aws-amplify/ui-react';
import CyberCenterContent from "./CyberCenterContent";

export default function WebsiteEncryptionInfo(props: { values: any; cyberScore: any}) {
  const { values, cyberScore } = props;

  return (
    <CyberCenterContent
      whatIsItContent={`An SSL certificate encrypts data exchanged between a user's browser and your website. This ensures information like login details and transactions remain private and secure.
      SSL certificates and website encryption protect client data during transmission, crucial for maintaining confidentiality, especially in sensitive sectors like accounting, legal, and healthcare.
      `}
      whyDoesItMatterContent={`Without an SSL certificate, your website's data transmission is unencrypted. This means sensitive information like client financial details, passwords, and personal data can be intercepted and read by hackers. The worst-case scenario is a significant data breach leading to financial fraud, identity theft of clients, legal consequences for your business, loss of client trust, and severe damage to your company's reputation. This can be particularly damaging for small businesses, where their reputaion with client confidentiality is paramount.
      `}
      howDoYouFixItContent={<>
        To get an SSL certificate, choose a reputable certificate provider. Many web hosting services offer SSL certificates, sometimes for free. Follow their process to install and activate the certificate on your website.
        <br /><br />
        Find the exact steps in our <Link href="https://www.thecyberscore.com/blog/tech-tips" target="_blank" rel="noopener noreferrer">Help Center</Link>.
      </>}
      values={["Website Encryption", values.WIScore, values.WIDetail]}
      cyberScore={cyberScore}
    />
  );
}
