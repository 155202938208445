import React from "react";
import { Link } from '@aws-amplify/ui-react';
import CyberCenterContent from "./CyberCenterContent";

export default function WebsiteFirewallInfo(props: { values: any; cyberScore: any}) {
  const { values, cyberScore } = props;

  return (
    <CyberCenterContent
      whatIsItContent={`A Web Application Firewall (WAF) is a security tool that monitors and filters traffic to and from your web applications, blocking harmful requests like hacking attempts.
      A WAF is important as it protects your website and web applications from attacks, ensuring client data and business operations remain secure.
      `}
      whyDoesItMatterContent={`If you don't have a Web Application Firewall (WAF), your business website could become an easy target for cyberattacks like SQL injections, cross-site scripting, and other exploits. The worst outcomes include a major data breach where sensitive client information, like financial records, is stolen or compromised. This can lead to financial fraud, legal liabilities for your business, a loss of client trust, and significant reputational damage. The impact of such an event can be devastating, especially for a small business where client confidence is key.
      `}
      howDoYouFixItContent={<>
        To get a WAF, choose a provider offering WAF services suitable for your business size and needs. Then, integrate it with your website following the provider's instructions, which may involve changing your domain's DNS settings.
        <br/><br/>
        For small businesses, selecting a Web Application Firewall (WAF) solution that is both effective and cost-efficient is important. Some recommended options include:
        <ul>
          <li><Link href="https://www.cloudflare.com/en-ca/application-services/products/waf/" target="_blank" rel="noopener noreferrer">Cloudflare</Link>: Known for its ease of use and good performance. It offers a free plan which is suitable for small websites.</li>
          <li><Link href="https://sucuri.net/website-firewall/" target="_blank" rel="noopener noreferrer">Sucuri</Link>: Offers a website security platform including a WAF. It is user-friendly and specifically caters to small and medium-sized businesses.</li>
          <li><Link href="https://www.wordfence.com/" target="_blank" rel="noopener noreferrer">Wordfence</Link>: Ideal for WordPress websites, providing a robust firewall and malware scanner designed for the WordPress environment.</li>
        </ul>
        Find the exact steps in our <Link href="https://www.thecyberscore.com/blog/tech-tips" target="_blank" rel="noopener noreferrer">Help Center</Link>.
      </>}
      values={["Website Firewall", values.WAFScore, values.WAFDetail]}
      cyberScore={cyberScore}
    />
  );
}
