import { get, post, put, del } from "aws-amplify/api";

type Options = {
  headers?: Record<string, string>;
  body?: Record<string, any>;
  queryParams?: Record<string, any>;
};

export async function icebergGet(path: string, options?: Options) {
  return await get({
    apiName: "IcebergCyberAPI",
    path: `/iceberg${path}`,
    options,
  }).response;
}
export async function icebergPost(path: string, options?: Options) {
  return post({ apiName: "IcebergCyberAPI", path: `/iceberg${path}`, options })
    .response;
}
export async function icebergPut(path: string, options?: Options) {
  return put({ apiName: "IcebergCyberAPI", path: `/iceberg${path}`, options })
    .response;
}
export async function icebergDel(path: string, options?: Options) {
  return del({ apiName: "IcebergCyberAPI", path: `/iceberg${path}`, options })
    .response;
}
