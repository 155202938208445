import { Button, Flex, TextField, Text } from "@aws-amplify/ui-react";
import React from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./styling/enterDomainField.css";
import { usePostHog } from "posthog-js/react";
import { extractDomainFromURL } from "../utils";
import { useBranding } from "../features/branding/hooks";

type Props = {
  labelHidden?: boolean;
};

export default function EnterDomainField({ labelHidden = false }: Props) {
  const [input, setInput] = React.useState("");
  const phClient = usePostHog();
  const navigate = useNavigate();

  const { branding, brandingId } = useBranding();

  const [searchParams] = useSearchParams();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const domain = extractDomainFromURL(input.split("@")[1] || input);

    // Capture the event with PostHog
    phClient.capture("domain_submitted", {
      domain: domain,
      environment:
        process.env.NODE_ENV === "production" ? "Production" : "Development",
    });
    navigate(`/score-search/${domain}?${searchParams.toString()}`);
  };

  return (
    <Flex
      as="form"
      justifyContent={"space-between"}
      padding={"0"}
      alignItems={"center"}
      data-attr="get-score-form"
      data-ph-capture-attribute-domain={extractDomainFromURL(input)}
      data-ph-capture-attribute-brandingid={brandingId}
      data-ph-capture-attribute-mspname={branding?.mspName}
      data-ph-capture-attribute-mpsid={branding?.memberId}
      onSubmit={handleSubmit}
      width={"100%"}
      gap={"xs"}
    >
      {!labelHidden && (
        <Text
          color={"white"}
          fontWeight={"bold"}
          width={"225px"}
          textAlign={"end"}
        >
          Get your Score:
        </Text>
      )}
      <TextField
        isRequired
        width={"100%"}
        placeholder="your-website.com"
        backgroundColor={"#FFFFFF"}
        style={{ border: "none" }}
        borderRadius={"medium"}
        alignItems={"center"}
        labelHidden
        label="Get your Score: "
        size={"small"}
        type="text"
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
        }}
      />
      <Button
        borderRadius={"medium"}
        color={"white"}
        backgroundColor={branding?.accentColor}
        gap={"small"}
        type="submit"
      >
        <FaSearch />
      </Button>
    </Flex>
  );
}
