import React from "react";
import {
  Flex,
  View,
  Image,
  ScrollView,
  Grid,
  Text,
  Link,
} from "@aws-amplify/ui-react";
import EnterDomainField from "../components/EnterDomainField";
import logo from "../assets/images/logo-dark.svg";
import gauge from "../assets/images/gauge.png";
import { useBranding } from "../features/branding/hooks";
import { usePageTitle } from "../hooks/usePageTitle";

export default function HackerView() {
  const { branding } = useBranding();

  usePageTitle(`Score Search | ${branding?.mspName ?? `The Cyber Score`}`);

  return (
    <>
      <ScrollView height={"100vh"} backgroundColor={branding?.primaryColor}>
        <Grid templateColumns={{ base: "1fr", large: "1fr 1fr" }}>
          <View height={{ base: "0vh", large: "100vh" }}>
            <View
              backgroundColor={branding?.accentColor ?? "#000080"}
              position={"relative"}
              style={{ clipPath: "polygon(100% 0,78% 100%,0 100%,0 0)" }}
              height={{ base: "0vh", large: "100vh" }}
            >
              <Flex
                direction={"column"}
                height={"100%"}
                flex={1}
                alignItems={"center"}
                justifyContent={"center"}
                alignContent={"center"}
              >
                <Text
                  fontWeight={"bold"}
                  color={"white"}
                  fontSize={"xxl"}
                  style={{
                    textShadow: "2px 2px 2px rgba(0, 0, 0, 0.4)",
                  }}
                >
                  Get Your {branding.pdfTitle ?? "Cyber Score"}
                </Text>
                <Image height={"300px"} alt="Gauge" src={gauge} />
              </Flex>
            </View>
          </View>

          <ScrollView height={"100vh"}>
            <Flex
              paddingTop={{ base: "medium", large: "10vh" }}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"start"}
            >
              <Flex
                width={"100%"}
                height={"90vh"}
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                padding={{ base: "small", large: "small xxxl" }}
              >
                <Flex justifyContent={"center"}>
                  {
                    <Link
                      flex={1}
                      isExternal={!!branding?.mspUrl}
                      href={branding.mspUrl ?? window.location.origin}
                      data-attr="click-logo"
                      data-ph-capture-attribute-mspname={branding.mspName}
                      data-ph-capture-attribute-mspurl={branding.mspUrl}
                      data-ph-capture-attribute-mpsid={branding?.memberId}
                    >
                      <Image
                        alt="Cyber Score logo"
                        src={branding?.yourLogo ?? logo}
                        maxHeight={"150px"}
                        maxWidth={"400px"}
                        objectFit={"contain"}
                      />
                    </Link>
                  }
                </Flex>
                <Text fontWeight={"semibold"} color={"white"}>
                  Enter your website to get your Score
                </Text>
                <Flex
                  width={"100%"}
                  maxWidth={"350px"}
                  justifyContent={"center"}
                  padding={"small"}
                  boxShadow={"large"}
                  borderRadius={"medium"}
                >
                  <View width={"100%"}>
                    <EnterDomainField labelHidden/>
                  </View>
                </Flex>
              </Flex>
            </Flex>
          </ScrollView>
        </Grid>
      </ScrollView>
    </>
  );
}
