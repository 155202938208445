import React from "react";
import { Link } from '@aws-amplify/ui-react';
import CyberCenterContent from "./CyberCenterContent";

export default function EmailSpoofingProtectionInfo(props: { values: any; cyberScore: any}) {
  const { values, cyberScore } = props;

  return (
    <CyberCenterContent
      whatIsItContent={`SPF, DKIM, and DMARC are email security methods. SPF verifies sender IP addresses, DKIM checks for message tampering, and DMARC coordinates SPF and DKIM and instructs email receivers on handling failures. Together, they prevent unauthorized sources from sending emails pretending to be from your domain. 
    For any business, protecting email integrity is crucial. These methods ensure emails from your company are authentic and trustworthy, crucial for maintaining client confidence and avoiding fraud.
    `}
      whyDoesItMatterContent={`Email impersonation can lead to fraud, data breaches, and loss of client trust. As an accounting firm, securing sensitive financial information is vital, and email impersonation protection helps safeguard this data.
      Without this protection, hackers could send emails from your business, tricking clients or employees into revealing confidential information, transferring money, or infecting systems with malware, leading to financial loss and reputational damage.
      `}
      howDoYouFixItContent={<>
      To set up SPF, DKIM, and DMARC for email protection, you need to add specific records to your domain's DNS settings. SPF records list authorized sending IPs, DKIM adds a digital signature to emails, and DMARC policies tell recipients how to handle emails failing these checks.
      <br /><br />
      Find the exact steps in our <Link href="https://www.thecyberscore.com/blog/tech-tips" target="_blank" rel="noopener noreferrer">Help Center</Link>.
      </>}
      values={["Email Impersonation Protection", values.ESSScore, values.ESSDetail]}
      cyberScore={cyberScore}
    />
  );
}
